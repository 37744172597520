<template>
<div style="width:100%; margin-top:10px;">
    <v-row> 
        <v-col cols=3>
            <v-skeleton-loader
                v-bind="attrs"
                type="article, actions"
                v-if="!cardsData.stats"
            ></v-skeleton-loader>

            <div class="wrapper-card offset-top" v-if="cardsData.stats">
                <v-card>
                    <div class="card-item">
                        <div class="card-item-content">
                            <div class="stat-block">
                                <h4>Пользователи</h4>
                                <div class="total-row">
                                    <span class="total-count">
                                        {{ cardsData.stats.totalUsers }}
                                    </span> 
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <span class="total-day" v-bind="attrs" v-on="on">+{{ cardsData.stats.totalUsersWeek }} чел</span>
                                        </template>
                                        <span>Зарегистрировалось за неделю</span>
                                    </v-tooltip>
                                </div>
                                <div class="actual-tag">
                                    <v-chip small outlined>
                                        Актуальные данные
                                    </v-chip>
                                </div>
                            </div>
                        </div>
                        <div class="card-img-icon">
                            <v-responsive max-width="111" min-height="175">
                                <div style=" background:url(/images/girl.png); width:111px; height:175px; background-size:cover; background-position:center center"></div>
                            </v-responsive>
                        </div>
                    </div>
                </v-card>
            </div>
        </v-col>

        <v-col cols=3>
            <v-skeleton-loader
                v-bind="attrs"
                type="article, actions"
                v-if="!cardsData.stats"
            ></v-skeleton-loader>

            <div class="wrapper-card offset-top" v-if="cardsData.stats">
                <v-card>
                    <div class="card-item">
                        <div class="card-item-content">
                            <div class="stat-block">
                                <h4>Кол-во ролей пользователей</h4>
                                <div class="total-row">
                                    <span class="total-count">{{ cardsData.stats.totalRoles }}</span> 
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <span class="total-day" v-bind="attrs" v-on="on">+{{ cardsData.stats.totalRolesWeek }} ролей</span>
                                        </template>
                                        <span>Добавлено за неделю</span>
                                    </v-tooltip>
                                </div>
                                <div class="actual-tag">
                                    <v-chip small outlined>
                                        Актуальные данные
                                    </v-chip>
                                </div>
                            </div>
                        </div>
                        <div class="card-img-icon">
                            <v-responsive max-width="86" min-height="175">
                                <div style="background:url(/images/boy.png); width:86px; height:175px; background-size:cover; background-position:center center"></div>
                            </v-responsive>
                        </div>
                    </div>
                </v-card>
            </div>
        </v-col>

        <!-- обращения в call-центр -->
        <v-col cols=6>
            <v-skeleton-loader
                v-bind="attrs"
                type="article, actions"
                v-if="!cardsData.requests"
            ></v-skeleton-loader>

            <div class="wrapper-card" v-if="cardsData.requests">
                <v-card min-height="176">
                    <div class="card-item">
                        <div class="card-item-content">
                            <div class="stat-block">
                                <h4>Горячая линия по вопросам обучения лиц с инвалидностью</h4>
                                <div class="total-row mt-0 pt-0 mb-0 pb-0">
                                    <span class="total-count">{{ cardsData.requests.totalRequests }}</span> 
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <span class="total-day" v-bind="attrs" v-on="on">+{{ cardsData.requests.totalRequestsWeek }} обращений</span>
                                        </template>
                                        <span>Добавлено за месяц</span>
                                    </v-tooltip>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="stat-sparkline">
                        <v-sparkline
                            :value="getValuesSparkline(cardsData.requests.requests)"
                            color="rgba(145,85,253, .7)"
                            height="32"
                            line-width="2"
                            padding="0.5"
                            stroke-linecap="round"
                            smooth
                            >
                        </v-sparkline>
                    </div>
                </v-card>
            </div>
        </v-col>

        <!-- События -->
        <v-col cols=2>
            <v-skeleton-loader
                v-bind="attrs"
                type="article, actions"
                v-if="!cardsData.events"
            ></v-skeleton-loader>

            <div class="wrapper-card" v-if="cardsData.events">
                <v-card min-height="176">
                    <div class="card-item">
                        <div class="card-item-content">
                            <div class="stat-block">
                                <div class="icon-dinamic mb-8">
                                    <v-avatar color="teal" elevation="1" size="40">
                                        <v-icon dark>mdi-calendar-check-outline</v-icon>
                                    </v-avatar>    
                                </div>
                                <h4>события</h4>
                                <div class="total-row mt-0 pt-0 mb-0 pb-0">
                                    <span class="total-count">
                                        <animated-number
                                            :value="cardsData.events.totalEvents"
                                            :formatValue="formatToNumberAnimated"
                                            :duration="1000"
                                        />
                                    </span> 
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <span class="total-day" v-bind="attrs" v-on="on"> + {{ cardsData.events.totalEventsWeek }} событий</span>
                                        </template>
                                        <span>Создано событий за последний месяц</span>
                                    </v-tooltip>
                                </div>
                            </div>
                        </div>
                    </div>
                </v-card>
            </div>
        </v-col>

        <!-- Регистрации в события -->
        <v-col cols=4>
            <v-skeleton-loader
                v-bind="attrs"
                type="article, actions"
                v-if="!cardsData.events"
            ></v-skeleton-loader>

            <div class="wrapper-card" v-if="cardsData.events">
                <v-card min-height="176">
                    <div class="card-item">
                        <div class="card-item-content">
                            <div class="stat-block">
                                <h4>Регистрации в событиях</h4>
                                <div class="total-row mt-0 pt-0 mb-0 pb-0">
                                    <span class="total-count">{{ cardsData.events.totalRegEvents }}</span> 
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <span class="total-day" v-bind="attrs" v-on="on">+{{ cardsData.events.totalRegEventsWeek }} человек</span>
                                        </template>
                                        <span>За последний месяц</span>
                                    </v-tooltip>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="stat-sparkline mt-5">
                        <v-sparkline
                            :value="getValuesSparkline(cardsData.events.eventsRegistration)"
                            color="rgba(255,87,34, .7)"
                            height="32"
                            line-width="2"
                            padding="0.5"
                            stroke-linecap="round"
                            smooth
                            >
                        </v-sparkline>
                    </div>
                </v-card>
            </div>
        </v-col>

        <!-- Организации -->
        <v-col cols=2>
            <v-skeleton-loader
                v-bind="attrs"
                type="article, actions"
                v-if="!cardsData.stats"
            ></v-skeleton-loader>

            <div class="wrapper-card" v-if="cardsData.stats">
                <v-card min-height="176" :to="{name: 'Organizations'}">
                    <div class="card-item">
                        <div class="card-item-content">
                            <div class="stat-block">
                                <div class="icon-dinamic mb-8">
                                    <v-avatar color="info" elevation="1" size="40">
                                        <v-icon dark>mdi-school-outline</v-icon>
                                    </v-avatar>    
                                </div>
                                <h4>Организации</h4>
                                <div class="total-row mt-0 pt-0 mb-0 pb-0">
                                    <span class="total-count">
                                        <animated-number
                                            :value="cardsData.stats.totalOrganization"
                                            :formatValue="formatToNumberAnimated"
                                            :duration="1000"
                                        />
                                    </span> 
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <span class="total-day" v-bind="attrs" v-on="on"> + {{ cardsData.stats.totalOrganizationFil }} фил.</span>
                                        </template>
                                        <span>Филиалов</span>
                                    </v-tooltip>
                                </div>
                            </div>
                        </div>
                    </div>
                </v-card>
            </div>
        </v-col>

        <!-- румц -->
        <v-col cols=2>
            <v-skeleton-loader
                v-bind="attrs"
                type="article, actions"
                v-if="!cardsData.stats"
            ></v-skeleton-loader>

            <div class="wrapper-card" v-if="cardsData.stats">
                <v-card min-height="176" :to="{name: 'Rumcs'}">
                    <div class="card-item">
                        <div class="card-item-content">
                            <div class="stat-block">
                                <div class="icon-dinamic mb-8">
                                    <v-avatar color="indigo" elevation="1" size="40">
                                        <v-icon dark>mdi-trending-up</v-icon>
                                    </v-avatar>    
                                </div>
                                <h4>РУМЦ</h4>
                                <div class="total-row mt-0 pt-0 mb-0 pb-0">
                                    <span class="total-count">
                                        <animated-number
                                            :value="cardsData.stats.totalRumcs"
                                            :formatValue="formatToNumberAnimated"
                                            :duration="1000"
                                        />
                                    </span> 
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <span class="total-day" v-bind="attrs" v-on="on"> & {{ cardsData.stats.totalRumcsUsers }} </span>
                                        </template>
                                        <span>Пользователей с правами доступа к РУМЦ</span>
                                    </v-tooltip>
                                </div>
                            </div>
                        </div>
                    </div>
                </v-card>
            </div>
        </v-col>

        <!-- Истории успеха -->
        <v-col cols=2>
            <v-skeleton-loader
                v-bind="attrs"
                type="article, actions"
                v-if="!cardsData.stats"
            ></v-skeleton-loader>

            <div class="wrapper-card" v-if="cardsData.stats">
                <v-card min-height="176">
                    <div class="card-item">
                        <div class="card-item-content">
                            <div class="stat-block">
                                <div class="icon-dinamic mb-8">
                                    <v-avatar color="light-green" elevation="1" size="40">
                                        <v-icon dark>mdi-clipboard-text-clock-outline</v-icon>
                                    </v-avatar>    
                                </div>
                                <h4>истории успеха</h4>
                                <div class="total-row mt-0 pt-0 mb-0 pb-0">
                                    <span class="total-count">
                                        <animated-number
                                            :value="cardsData.stats.totalStories"
                                            :formatValue="formatToNumberAnimated"
                                            :duration="1000"
                                        />
                                    </span> 
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <span class="total-day" v-bind="attrs" v-on="on"> + {{ cardsData.stats.totalStoriesWeek }}</span>
                                        </template>
                                        <span>За последнии 180 месяцев</span>
                                    </v-tooltip>
                                </div>
                            </div>
                        </div>
                    </div>
                </v-card>
            </div>
        </v-col>

        <v-col cols=5>
            <v-skeleton-loader
                v-bind="attrs"
                type="article, actions"
                v-if="!cardsData.calls"
            ></v-skeleton-loader>

            <div class="wrapper-card" v-if="cardsData.calls">
                <v-card min-height="176" v-if="cardsData.calls.rowsCalls">
                    <div class="card-item">
                        <div class="cart-title">
                            <h3>Обращения в call-центры</h3>
                        </div>
                    </div>
                    <apexchart height="350px" type="radar" :options="cardsData.calls.rowsCalls" :series="cardsData.calls.rowsCalls.series"></apexchart>
                </v-card>
            </div>
        </v-col>

        <v-col cols=7>
            <v-skeleton-loader
                v-bind="attrs"
                type="article, actions"
                v-if="!cardsData.calls"
            ></v-skeleton-loader>

            <div class="wrapper-card" v-if="cardsData.calls">
                <v-card min-height="176">
                    <div class="card-item">
                        <div class="cart-title">
                            <h3>Обращения в call-центры РУМЦ за 30 дней</h3>
                        </div>
                    </div>
                    <apexchart height="350px" type="bar" :options="cardsData.calls.rowsCallsRumcs" :series="cardsData.calls.rowsCallsRumcs.series"></apexchart>
                </v-card>
            </div>
        </v-col>

        <v-col cols=12>
            <v-skeleton-loader
                v-bind="attrs"
                type="article, actions"
                v-if="!cardsData.audiencifies"
            ></v-skeleton-loader>

            <div class="wrapper-card" v-if="cardsData.audiencifies">
                <v-card min-height="176">
                    <div class="card-item">
                        <div class="cart-title">
                            <h3>Audiences</h3>
                        </div>
                    </div>
                    <apexchart height="350px" type="bar" :options="cardsData.audiencifies.rowsAudiencifies" :series="cardsData.audiencifies.rowsAudiencifies.series"></apexchart>
                </v-card>
            </div>
        </v-col>

    </v-row>
</div>
</template>

<script>
import AnimatedNumber from "animated-number-vue";
export default {
    data() {
        return {
            cards: {},
            cardsData: {},

            isLoading: false,

            attrs: {
                class: 'mb-2',
                boilerplate: true,
                elevation: 2,
            },
        }   
    },
    components: {
        AnimatedNumber
    },
    methods: {
        formatToNumberAnimated(value) {
            return value.toFixed(0);
        },
        getLabesSparkline(arr) {
            if ( typeof arr != "object" ) return;

            let _ret = []
            arr.map((item) => _ret.push(item._date))
            return _ret
        },
        getValuesSparkline(arr) {
            if ( typeof arr != "object" ) return;

            let _ret = []
            arr.map((item) => _ret.push(parseInt(item._count)))
            return _ret
        },
        getCardData(name){
            return this.$http.get(`dashboard/card/${name}`).then((response) => {
                this.$set(this.cardsData, name, response.data.items)
                return true;
            }).catch(() => {});
        },
        getCards() {
            this.axios.get("dashboard/cards").then((response) => {
                this.cards = response.data.cards

                for(let i in this.cards) {
                    setTimeout(() => {
                        this.getCardData(this.cards[i])
                    },1)
                }
            });
        }
    },
    created(){
        this.getCards()
    }
}
</script>

<style scoped>
.wrapper-card {position: relative;}
.wrapper-card.offset-top { padding-top:30px; }
.card-item { display: flex; justify-content: space-between; }
.card-item .cart-title { padding:15px 0 0 20px; }
.card-item .cart-title h3 { color:#7E7786; }
.card-img-icon { margin-top:-30px; margin-right:20px; }
.card-item-content { padding:20px; }
.card-item-content .stat-block h4 { text-transform: lowercase; font-weight: bold; color:#7E7786; font-size:13px; }
.card-item-content .stat-block .total-row { padding:10px 0; display: flex; align-items: center; }
.card-item-content .stat-block .total-row .total-count { font-size:28px; font-weight: 600; color:#7E7786; }
.card-item-content .stat-block .total-row .total-day { font-weight: 200; font-size:14px; padding-left:7px; padding-top:2px; color:#61CD10; }
</style>